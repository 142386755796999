<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <!-- Organization -->
    <base-input
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <organization-selector
        :organization="establishment.organization.id"
        :filterable="true"
        :showAll="false"
        @organizationChanged="
          (organizationId) => {
            establishment.organization.id = organizationId;
            establishment.serviceCenter.id = null;
            establishment.allowedLocations = [];
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="`${$t('COMMON.LOCATION')}`"
      :placeholder="$t('COMMON.LOCATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
    >
      <locations-selector
        :locations="establishment.allowedLocations"
        :filterable="true"
        :showAll="false"
        :multiple="true"
        :organization="establishment.organization.id"
        @locationsChanged="
          (locations) => {
            establishment.allowedLocations = locations;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.location" />

    <!-- Establishment type -->
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.TYPE')} (*)`"
          :placeholder="$t('COMMON.TYPE')"
          @change="
            () => {
              onFormChanged();
            }
          "
        >
          <el-select
            :name="`${$t('ESTABLISHMENTS.ESTABLISHMENT_TYPE')}`"
            :placeholder="$t('ESTABLISHMENTS.ESTABLISHMENT_TYPE')"
            v-model="establishment.establishment_type"
          >
            <el-option
              :value="ESTABLISHMENT_TYPE_ELEMENTARY"
              :label="$t('ESTABLISHMENTS.ESTABLISHMENT_TYPE_ELEMENTARY')"
            />
            <el-option
              :value="ESTABLISHMENT_TYPE_SECONDARY"
              :label="$t('ESTABLISHMENTS.ESTABLISHMENT_TYPE_SECONDARY')"
            />
            <el-option
              :value="ESTABLISHMENT_TYPE_COLLEGE"
              :label="$t('ESTABLISHMENTS.ESTABLISHMENT_TYPE_COLLEGE')"
            />
            <el-option
              :value="ESTABLISHMENT_TYPE_UNIVERSITY"
              :label="$t('ESTABLISHMENTS.ESTABLISHMENT_TYPE_UNIVERSITY')"
            />
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.establishment_type" />
      </div>

      <div class="col">
        <base-input
          :label="`${$t('COMMON.SERVICECENTER')} (*)`"
          :placeholder="$t('COMMON.SERVICECENTER')"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SERVICECENTERS)"
        >
          <serviceCenter-selector
            :serviceCenter="establishment.serviceCenter.id"
            :filterable="true"
            :showAll="false"
            :filterOrganization="establishment.organization.id"
            :disabled="!establishment.organization.id"
            @serviceCenterChanged="
              (serviceCenterId) => {
                establishment.serviceCenter.id = serviceCenterId;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.establishment" />
      </div>
    </div>

    <!-- Company name -->
    <base-input
      :label="`${$t('COMMON.COMPANY_NAME')} (*)`"
      v-model="establishment.company_name"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.company_name" />

    <div class="row">
      <div class="col">
        <!-- Fiscal number -->
        <base-input
          :label="`${$t('COMMON.FISCAL_NUMBER')}`"
          v-model="establishment.fiscal_number"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.fiscal_number" />
      </div>
      <div class="col">
        <!-- Customer number -->
        <base-input
          :label="`${$t('COMMON.CUSTOMER_NUMBER')}`"
          v-model="establishment.customer_number"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.customer_number" />
      </div>
    </div>

    <!-- Email address -->
    <base-input
      :label="`${$t('COMMON.EMAIL')} (*)`"
      v-model="establishment.email"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.email" />

    <!-- z number -->
    <base-input :label="`${$t('COMMON.PHONE')} (*)`">
      <phone-number-input
        :phoneNumber="establishment.phone"
        @phoneNumberChanged="
          (phone) => {
            establishment.phone = phone;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.phone" />

    <div class="row">
      <!-- Country -->
      <div class="col">
        <base-input
          :label="`${$t('COMMON.COUNTRY')} (*)`"
          :placeholder="$t('COMMON.COUNTRY')"
        >
          <country-selector
            :country="establishment.country"
            :filterable="true"
            :showAll="false"
            @countryChanged="
              (country) => {
                establishment.country = country;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.country" />
      </div>

      <!-- State  -->
      <div class="col">
        <base-input
          :label="`${$t('COMMON.STATE')} (*)`"
          :placeholder="$t('COMMON.STATE')"
        >
          <state-selector
            :country="establishment.country"
            :state="establishment.state"
            :filterable="true"
            :showAll="false"
            @stateChanged="
              (state) => {
                establishment.state = state;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.state" />
      </div>

      <!-- City -->
      <div class="col">
        <base-input
          :label="`${$t('COMMON.CITY')} (*)`"
          v-model="establishment.city"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.city" />
      </div>

      <!-- ZIP Code  -->
      <div class="col">
        <base-input
          :label="`${$t('COMMON.ZIPCODE')} (*)`"
          v-model="establishment.zipcode"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.zipcode" />
      </div>
    </div>

    <!-- Address -->
    <base-input
      :label="`${$t('COMMON.ADDRESS')} (*)`"
      v-model="establishment.address"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.address" />

    <!-- Tags -->
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.TAGS')}`"
          :placeholder="`${$t('COMMON.TAGS')}`"
        >
          <tags-selector
            :tags="establishment.tags"
            @tagsChanged="
              (tags) => {
                establishment.tags = tags;
                onFormChanged();
              }
            "
            :disabled="!establishment.organization.id"
            :organization="establishment.organization.id"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.tags" />
      </div>
    </div>

    <h2>{{ $t("INVOICES.BILLING_ADDRESS") }}</h2>

    <base-checkbox
      v-model="billingAddressSameAsEstablishment"
      class="mb-3"
      @input="applyEstablishmentDataToBilling"
    >
      <span class="form-control-label">
        {{ $t("ESTABLISHMENTS.BILLING_INFORMATIONS_SAME_AS_ESTABLISHMENT") }}
      </span>
    </base-checkbox>

    <billing-informations-form
      v-if="!billingAddressSameAsEstablishment"
      :billingInformationsData="establishment"
      :formErrors="formErrors"
      @onChangeBillingInformations="billingInformationsChanged"
    />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          establishment.id
            ? $t("ESTABLISHMENTS.EDIT_ESTABLISHMENT")
            : $t("ESTABLISHMENTS.ADD_ESTABLISHMENT")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import { BILLING_ENTITY_TYPE_COMPANY } from "@/constants/billingInformations";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import CountrySelector from "@/components/CountrySelector.vue";
import TagsSelector from "@/components/TagsSelector.vue";
import StateSelector from "@/components/StateSelector.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import BillingInformationsForm from "@/components/BillingInformationsForm.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import ServiceCenterSelector from "@/components/ServiceCenterSelector.vue";
import {
  ESTABLISHMENT_TYPE_ELEMENTARY,
  ESTABLISHMENT_TYPE_SECONDARY,
  ESTABLISHMENT_TYPE_COLLEGE,
  ESTABLISHMENT_TYPE_UNIVERSITY,
} from "@/constants/establishments";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    CountrySelector,
    StateSelector,
    OrganizationSelector,
    PhoneNumberInput,
    TagsSelector,
    BillingInformationsForm,
    LocationsSelector,
    [Select.name]: Select,
    [Option.name]: Option,
    ServiceCenterSelector,
  },

  mixins: [formMixin],

  props: ["establishmentData", "formErrors", "loading"],

  data() {
    const me = this.$store.getters["profile/me"];
    const establishmentData = { ...this.establishmentData };

    if (this.$currentUserIsLevelOrganization()) {
      establishmentData.organization.id = me.organization.id;
    }
    return {
      establishment: establishmentData,
      billingAddressSameAsEstablishment: false,
      ESTABLISHMENT_TYPE_ELEMENTARY: ESTABLISHMENT_TYPE_ELEMENTARY,
      ESTABLISHMENT_TYPE_SECONDARY: ESTABLISHMENT_TYPE_SECONDARY,
      ESTABLISHMENT_TYPE_COLLEGE: ESTABLISHMENT_TYPE_COLLEGE,
      ESTABLISHMENT_TYPE_UNIVERSITY: ESTABLISHMENT_TYPE_UNIVERSITY,
    };
  },

  methods: {
    async handleSubmit() {
      const establishmentData = cloneDeep(this.establishment);
      const me = this.$store.getters["profile/me"];
      if (me.restrict_to_locations) {
        if (establishmentData.allowedLocations.length === 0) {
          establishmentData.allowedLocations = [
            ...me.allowedLocations.map((item) => ({
              type: "locations",
              id: item.id,
            })),
          ];
        }
      }
      this.$emit("establishmentSubmitted", establishmentData);
    },

    billingInformationsChanged(billingInformations) {
      this.establishment = { ...this.establishment, ...billingInformations };
      this.onFormChanged();
    },

    onFormChanged() {
      if (this.billingAddressSameAsEstablishment) {
        this.establishment.billing_entity_type =
          BILLING_ENTITY_TYPE_COMPANY;
        this.establishment.billing_company_name =
          this.establishment.company_name;
        this.establishment.billing_firstname = this.establishment.firstname;
        this.establishment.billing_lastname = this.establishment.lastname;
        this.establishment.billing_country = this.establishment.country;
        this.establishment.billing_state = this.establishment.state;
        this.establishment.billing_city = this.establishment.city;
        this.establishment.billing_zipcode = this.establishment.zipcode;
        this.establishment.billing_address = this.establishment.address;
        this.establishment.billing_email = this.establishment.email;
        this.establishment.billing_firstname = "N/A";
        this.establishment.billing_lastname = "N/A";
      }
      this.$emit("formChanged");
    },

    applyEstablishmentDataToBilling(value) {
      this.onFormChanged();
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    establishmentData(establishmentData) {
      if (establishmentData) {
        this.establishment = {
          ...this.establishment,
          ...cloneDeep(establishmentData),
        };
      }
    },
  },
};
</script>
