import {
  BILLING_ENTITY_TYPE_COMPANY,
} from "@/constants/billingInformations";

export default {
  type: "establishments",
  establishment_type: null,
  fiscal_number: null,
  customer_number: null,
  tags: null,
  company_name: null,
  email: null,
  phone: null,
  country: "Canada",
  state: "Quebec",
  city: null,
  zipcode: null,
  address: null,
  relationshipNames: ["organization", "tags", "allowedLocations", "serviceCenter"],
  organization: {
    type: "organizations",
    id: null,
  },
  serviceCenter: {
    type: "service-centers",
    id: null,
  },
  customer: {
    type: "customers",
    id: null,
  },
  tags: [],
  allowedLocations: [],

  
  billing_entity_type: BILLING_ENTITY_TYPE_COMPANY,
  billing_company_name: null,
  billing_firstname: null,
  billing_lastname: null,
  billing_country: "Canada",
  billing_state: "Quebec",
  billing_city: null,
  billing_zipcode: null,
  billing_address: null,
  billing_email: null,
};
